<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.member_no')"
              label-for="member_no"
            >
              <b-form-input
                id="member_no"
                :value="searchFields.member_no"
                @input="inputChange($event, 'member_no')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.id')"
              label-for="id"
            >
              <b-form-input
                id="id"
                :value="searchFields.id"
                @input="inputChange($event, 'id')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.mobile')"
              label-for="mobile"
            >
              <b-form-input
                id="name"
                type="number"
                :value="searchFields.mobile"
                @input="inputChange($event, 'mobile')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.email')"
              label-for="email"
            >
              <b-form-input
                id="name"
                :value="searchFields.email"
                @input="inputChange($event, 'email')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :have-dropdown-actions="true"
    >
      <template #table-header-link>
        <router-link
          :to="{ name: 'comparisons-logs' }"
          class="btn btn-primary"
        >
          <spanلاثق
            class="text-capitalize"
            role="link"
          >{{ $t('comparison logs') }}</spanلاثق>
        </router-link>
      </template>
      <template v-slot:dropdown_actions="{ item, generalActions }">
        <b-dropdown-item
          :to="{ name: 'customer-search', params: { customer_id: item.id } }"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
          />
          <span>{{ $t('Search Archive') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'favorite-ads', params: { customer_id: item.id } }"
        >
          <feather-icon
            icon="StarIcon"
            class="mr-50"
          />
          <span>{{ $t('Favorite Ads') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          :to="{ name: 'advertiser-ratings', params: { customer_id: item.id } }"
        >
          <feather-icon
            icon="BarChartIcon"
            class="mr-50"
          />
          <span>{{ $t('Advertiser Ratings') }}</span>
        </b-dropdown-item>
      </template>
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'customers',
      singularName: 'customer',
      searchFields: {
        id: null,
        name: null,
        member_no: null,
        status: null,
        mobile: null,
        email: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 'active',
          name: this.$t('statuses.active'),
        },
        {
          id: 'inactive',
          name: this.$t('statuses.inactive'),
        },
      ]
    },
  },
}
</script>
